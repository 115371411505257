<template>
  <div style=" background-color: #f4f5f7; padding-bottom: 50px; margin-top: -12px; ">
    <div  class="gigContainer">
      <div>

        <div class="main-img-div" :style=" `background: url(${bannerImageUrl})`">

          <div style="padding-top: 150px; padding-left: 15px;">
            <p class="title">{{ title }}</p>
            <p class="sub-title">{{ subtitle }}</p>
          </div>
        </div>

        <div v-if="!pageLoaded">
          <template v-for="y in 3" style="margin-top: 40px">
            <div :key="y" style="margin-top:50px; display: flex; margin-left: 20px; margin-right: 10px; justify-content: space-between; align-items: center">
              <router-link class="cat-story-see-all" target="_blank" :disabled="true" to="">Tümü</router-link>
            </div>
            <div class="gig-list-container">
              <div style="display: flex;flex-wrap: wrap; margin-top: 1px">
                <div v-for="x in 3" class="card-container">
                  <div class="card-image-container card">
                  </div>
                  <div class="card-detail">
                    <div class="card-detail-container">
                      <div class="card-detail-header-large card">

                      </div>
                      <div class="card-detail-header-small card">

                      </div>
                    </div>
                    <div class="card-detail-user-container ">
                      <div class="card-detail-user">
                        <div class="card-detail-user-image card"></div>
                        <div class="card-detail-user-name card"></div>
                      </div>
                      <div class="card-detail-currency card">
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div class="card-detail-seperator card">

                  </div>
                  <div class="card-like-currency">
                    <div class="card-like-icon card"></div>
                    <div class="card-currency-icon card"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else>
          <div v-for="(section, index) in sections" :key="index" style="margin-top: 40px;">
            <template v-if="section.type === 'likeList'">
              <div style="margin-top:50px; display: flex;  margin-left: 20px; margin-right: 10px; justify-content: space-between; align-items: center">
                <p v-if="pageLoaded" class="cat-story-title"><span>{{ section.title }}</span></p>
                <router-link class="cat-story-see-all" target="_blank" :to=" `/liste/${slugify(section.likeListTitle)}-${section.externalId}`">Tümü</router-link>
              </div>

              <div class="gig-list-container">
                <single-gig :impression-u-u-i-d="impressionUUID" :item="entity"  v-for="entity in section.entities" class="gig-list-gig"  :key="entity.uuid"/>
                <div v-if="!section.entities || section.entities.length === 0" class="ee">
                  <img style="width: 302px; margin-top: 30px;" src="https://gcdn.bionluk.com/uploads/message/b7de1367-db4c-4667-adcb-7ee6ef7487ce.png" />
                  <p class="ee-title">Bu listede hiç iş ilanı yok.</p>
                  <p class="ee-text">
                    Listeye iş ilanı eklemek için ilgini çeken bir iş ilanı sayfasına git ve o sayfa üzerinden bu listeye o ilanı ekle.
                  </p>
                  <custom-button button-type="green" style="margin-top: 30px; margin-bottom: 30px" @click="$router.push('/bugun')">İlanları Keşfet</custom-button>
                </div>
              </div>
            </template>
            <template v-if="section.type === 'generic'">
              <div
                class="go-request"
                :style="section.colors.length > 1 ? `background-image: linear-gradient(to right, ${section.colors[0]}, ${section.colors[1]});` : `background-color: ${section.colors[0]}`">
                <div class="left">
                  <p class="title">{{ section.genericText }}</p>
                  <button @click="$router.push(section.buttonLink)" class="cuper-red-button" style="margin-top: 20px">{{ section.buttonTitle }}</button>
                </div>
                <img class="right" :src="section.mobileImageUrl">
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/pages/components/CustomButton";
import singleGig from "@/pages/components/singleGig";
import mixin from './mixin'
export default {
  name: "src-pages-body-marketplace-liste-v1",
  components:{singleGig, CustomButton},
  mixins: [mixin],
  data() {
    return {
      id: null,
      bannerImageUrl: null,
      title: null,
      likeListTitle: null,
      externalId: null,
      subtitle: null,
      pageLoaded: false,
      impressionUUID:'',
      dummyGigs:[],
      sections: []
    }
  },
  created() {
    this.init(true)
  }
}
</script>

<style scoped lang="scss">



.cat-story-title{
  font-size: 24px;
  font-weight: normal;
  line-height: 1.3;
  color: #2d3640;
  width: 80%;

  span{
    font-weight: 600;
  }
}

.cat-story-see-all{
  color: #00a575;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.cat-story-sub-title{
  margin-top: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #5e6b79;

  span {
    font-weight: bold;
    color: #26ca9a;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}


.main-img-div{

  width: 100%;
  height:240px;
  background-color: #000;
  background-size: cover!important;

  .title{
    color: #fff; font-weight: 500; font-size: 24px;
  }

  .sub-title{
    color: #fff; font-size: 16px; font-weight: 300; margin-top: 10px;
  }
}


.followers-count{
  padding-left: 30px;
  padding-right: 30px;
  height: 34px;
  border-radius: 2px;
  border: dashed 1px #bfc8d2;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  .inner-text {
    height: 18px;
    font-size: 14px;
    text-align: center;
    color: #5e6b79;
  }
  &:hover{
    border: solid 1px #2d3740;
  }
}


.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-to {
  animation: bounce-out .0s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}


.card-container {
  width: 278px;
  height: 298px;
  background-color: #ffffff;
  border: solid 1px #dfe4ec;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;

  &:nth-child(4n+1) {
    margin-left: 0;
  }
}

.card-image-container {
  height: 148px;
  background-color: #fafbfc;
  border-bottom: solid 1px #dfe4ec;
}

.card-detail-container {
  padding: 15px;
}

.card-detail-header-large {
  width: 234px;
  height: 16px;
  background-color: #e1e6ed;
  margin-bottom: 4px;
}

.card-detail-header-small {
  width: 117px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-image {
  width: 28px;
  height: 28px;
  background-color: #dfe4ec;
  border-radius: 50%;
  margin-right: 8px;
}

.card-detail-user-name {
  width: 50px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-currency {
  width: 63px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 15px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe4ec;
}

.card-detail-user {
  display: flex;
  align-items: center;
}

.card-like-icon {
  width: 16px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-currency-icon {
  width: 42px;
  height: 20px;
  background-color: #bdc5d0;
}

.card-like-currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 15px;
}

.card {
  animation-name: loading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}


.cardBoxContainer{

  transition: transform 0.5s ease 0s;
  visibility: visible;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y:hidden;
  .cardBoxFlexC{
    display: flex;
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0;
    }
  }


  .reText{


    text-align: center;
    margin-top: 23px;
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;

    display: flex;
    span{
      font-weight: bold;
    }
    p{
      line-height: 1;
      margin-left: 5px;
    }
    .reTextIcon /deep/ {
      width: 14px;
      height: 14px;
      path {
        fill: #8b95a1;
      }
    }
  }

  .cardBox{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 200px;
    height: 311px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .cardOnline{
      position: absolute;
      margin-left: 170px;
      margin-top:15px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .profileButton{
      background-color: white;
      width: 140px;
      height: 34px;
      padding-bottom: 2px;
      border-radius: 2px;
      border: solid 1px rgba(45, 54, 64, 0.2);

      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
      transition: .2s;
      &:hover{
        background-color: #2d3640!important;
        color:#fff
      }

    }
    .username{
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: #2d3640;
    }

    .title{
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 16px;
      margin-top: 4px;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: -0.5px;
      text-align: center;
      color: #8b95a1;
    }


    .rating{

      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .rate{
        margin-left: 2px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffbe00;
      }

      .count{
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;
      }
    }

  }

  .pBox{
    width: 277px;
    height: 274px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    position: relative;
    display: flex;


    flex-direction: column;
    align-items: center;
    margin-left: 28px;
    margin-bottom: 30px;

    .header{

      display: flex;
      align-items: center;
      height: 67px;
      width: 100%;

    }

    .media{
      width: 277px; height: 158px;
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .avatar{
      margin-left: 11px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .username-p{

      font-size: 11px;
      font-weight: 300;
      color: #5e6b79;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 14px;
      max-width: 200px;

      a{
        font-size: 11px;
        font-weight: 600;
        color: #fa4a5e;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .title{
      font-weight: 600;
      color: #2d3640;
      font-size: 14px;
    }


  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -130%);
    height: 42px;
    width: 42px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 42px;
      width: 42px;
    }
  }

}



.freelancerAvatar {
  width: 91px;
  height: 91px;
  border-radius: 100%;
  border: 2px solid #eaeaea;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 4;
}

.rainDropcontainer {
  position: relative;
  display: block;
  width: 132px;
  height: 132px;
  margin-top: 15px;
}


.firstLayer {
  width: 108px;
  height: 108px;
  border-radius: 100%;
  opacity: 0.1;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 3;
}

.secondLayer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  opacity: 0.08;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
}

.thirdLayer {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  opacity: 0.05;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}


.ee{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ee-title{
    margin-top: 50px;
    font-size: 26px;
    font-weight: 600;
    color: #2d3640;
  }
  .ee-text{
    width: 547px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 300;

    line-height: 1.88;
    text-align: center;

    color: #5e6b79;
  }
}

.gig-list-container {

  position: relative;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-top: 30px;


  .gig-list-gig{

    margin-left: 24px;
    &:nth-child(4n+1) {
     // margin-left: 0;
    }
  }

  &:hover {
    .swiper-button-prev, .swiper-button-next {
      visibility: visible;
    }
  }
}

.gigContainer {
  padding-bottom: 10px;

  margin: 0 auto;

}

.gig-main-div {
  display: inline-block;
  margin-bottom: 40px;
  margin-left: 30px;
  width: 263px;
  height: 296px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  vertical-align: top;
}



.go-request{
  border-radius: 8px;
  background-size: cover;
  width: 335px;
  height: 200px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left{
    margin-left: 15px;
    .title{
      width: 278px;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.44;
      color: #ffffff;
      span{
        font-weight: bold;
      }
    }
    .cuper-red-button{
      width: 200px;
      height: 44px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }

  }

  .right{
    position: absolute;
    margin-left: 200px;
    width: 123px;
    height: 141px;
  }

}


</style>
